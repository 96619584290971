<template>
  <Stack id="layout" tag="main" direction="col" gap="none" align="center" justify="between" class="min-h-screen">
    <SimpleHeader title="Secure checkout" icon-name="padlock-unlocked" :subtitle="localSubtitle" :flex-row="true" />
    <Stack class="w-full flex-grow py-sm md:py-3xl">
      <slot />
    </Stack>
    <SimpleFooter />
  </Stack>
  <NotificationToast />
</template>
<script lang="ts" setup>
import { SimpleLayoutProps } from "./types";
import SimpleHeader from "@/components/Global/SimpleHeader/SimpleHeader.vue";

const { title, description } = withDefaults(defineProps<SimpleLayoutProps>(), {
  title: "MKM building Supplies | Your local experts",
  description: "MKM Building Supplies, the UK's largest independent builders' merchant.",
});

const { selectedBranch } = useBranches();

const localSubtitle = computed(() => {
  const phone = selectedBranch?.value?.contacts?.phone;
  return phone ? `Need some help? ${phone}` : "";
});

useHead({
  title: `${title}`,
  meta: [
    {
      name: "description",
      content: description,
    },
  ],
});
</script>
